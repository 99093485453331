
import { getDictionary,
         retrieveButter } from '~/utils/butterUtils'

export default {
  head () {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription
        }
      ],
      link: [ 
        {
          rel: 'canonical',
          href: process.env.url+this.$route.path
        }
      ]
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/about-us.vue asyncData ')
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    pageMetaDescription () {
      return this.dict.SEO_A11
    },
    pageTitle () {
      return process.env.siteTitle + " | " + this.dict.SEO_A10
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics.about_us : null
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
  },
  mounted () {
    this.isLoading = false
  }
}
